import { useEffect } from 'react';

import { withAnalyticsEvents, type WithAnalyticsEventsProps } from '@atlaskit/analytics-next';

import { usePendingUsers } from '../../hooks/usePendingUsers';
import useUsersTeamsData from '../../hooks/useUsersTeamsData';
import { type Product, type RenotifyExperimentProps } from '../../types';
import { startMeasure, stopMeasure } from '../../utils/performance';
import { triggerAnalyticsForFetchingData, triggerAnalyticsForPreFetchData } from '../analytics';

interface Props {
	cloudId: string;
	orgId?: string;
	userId: string;
	product: Product;
	tenantUrl?: string;
	renotifyExperiment?: RenotifyExperimentProps;
}

// A dummy component is rendered to trigger to pre-fetch users and teams data.
export function UsersTeamsPreFetchInternal({
	cloudId,
	orgId,
	userId,
	product,
	tenantUrl,
	createAnalyticsEvent,
	renotifyExperiment,
}: Props & WithAnalyticsEventsProps) {
	useEffect(() => {
		triggerAnalyticsForPreFetchData(createAnalyticsEvent);
		// measure duration time of fetching users and teams data
		startMeasure('fetchingData');
	}, [createAnalyticsEvent]);

	const { isLoading } = useUsersTeamsData(cloudId, userId, product, tenantUrl, undefined, orgId);

	/*
	 * This hook checks the renotify experiments eligibility passed from Jira
	 * and will only take action if enrolled. It makes a single call to
	 * Experiment-API which proxies a call to Hydra and Identity Gatekeeper
	 * to return users who have been invited to Jira but not accepted.
	 */
	usePendingUsers({ cloudId, startFetching: true, renotifyExperiment });

	// stop to measure fetching data time when isLoading is finished.
	useEffect(() => {
		if (!isLoading) {
			stopMeasure('fetchingData', (duration, startTime) => {
				triggerAnalyticsForFetchingData(createAnalyticsEvent, duration, startTime);
			});
		}
	}, [createAnalyticsEvent, isLoading]);

	return null;
}

export const UsersTeamsPreFetch = withAnalyticsEvents()(UsersTeamsPreFetchInternal);
