import React, { useContext } from 'react';

import { lazyForPaint } from 'react-loosely-lazy';

import { MenuGroup } from '@atlaskit/menu';

import { PeopleMenuContext } from '../../context/PeopleMenuContext';
import useUsersTeamsData from '../../hooks/useUsersTeamsData';

import { MenuGroupPeople } from './MenuGroupPeople';
import { MenuGroupTeams } from './MenuGroupTeams';

export const MenuContent = lazyForPaint(
	() =>
		// eslint-disable-next-line import/dynamic-import-chunkname
		import(/* webpackChunkName: "async-people-menu-content" */ './MenuContent'),
);

// avoid importing from other file like `./utils.tsx`
export const MAX_POPUP_WIDTH = 480;
export const MIN_POPUP_WIDTH = 320;
export const MAX_POPUP_HEIGHT = 'calc(100vh - 79px)';

export function MenuContentFallback() {
	const { cloudId, tenantUrl, userId, product, testId, orgId } = useContext(PeopleMenuContext);

	// kick off some requests sooner
	useUsersTeamsData(cloudId, userId, product, tenantUrl, undefined, orgId);

	return (
		<MenuGroup
			testId={`${testId}-group-loading`}
			maxWidth={MAX_POPUP_WIDTH}
			maxHeight={MAX_POPUP_HEIGHT}
			minWidth={MIN_POPUP_WIDTH}
		>
			<MenuGroupPeople isLoading={true} />
			<MenuGroupTeams isLoading={true} />
		</MenuGroup>
	);
}
