import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';

import { packageContext } from '../../utils/package-context';

type UFOCustomData = {
	[key: string]: string | number | boolean | UFOCustomData | undefined;
};

type UFOEndStateConfig = {
	force?: boolean;
	metadata?: UFOCustomData;
};
class UFOExperienceWithPackageContext extends UFOExperience {
	async success() {
		return super.success({ metadata: { ...packageContext } });
	}

	async failure(config?: UFOEndStateConfig) {
		return super.failure({
			force: config?.force,
			metadata: { ...(config?.metadata ?? {}), ...packageContext },
		});
	}

	async abort() {
		return super.abort({ metadata: { ...packageContext } });
	}
}

export const renderPeopleNavMenuExp = new UFOExperienceWithPackageContext(
	'render-people-nav-menu',
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
		platform: { component: 'people' },
	},
);
