/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { jsx } from '@compiled/react';

import { PrimaryButton } from '@atlaskit/atlassian-navigation';
import ChevronIconLegacy from '@atlaskit/icon/glyph/chevron-down';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';

import { RenotifySpotlightTriggerChevron } from './styled';

interface Props {
	testId: string;
	triggerProps: any;
	children: React.ReactNode;
}

function RenotifyMenuButton(props: Props) {
	const { testId, triggerProps, children } = props;

	return (
		<PrimaryButton
			data-testid={testId}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className="renotify-menu-button"
			iconAfter={
				<RenotifySpotlightTriggerChevron>
					<ChevronDownIcon
						label="dropdown-chevron"
						spacing="spacious"
						LEGACY_fallbackIcon={ChevronIconLegacy}
					/>
				</RenotifySpotlightTriggerChevron>
			}
			{...triggerProps}
		>
			{children}
		</PrimaryButton>
	);
}

export default RenotifyMenuButton;
