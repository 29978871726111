import React from 'react';

import { SkeletonItem, type StatelessCSSFn } from '@atlaskit/menu';
import { N20A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { ICON_SIZE } from './utils';

const cssFn: StatelessCSSFn = () => {
	return {
		'&::before': {
			backgroundColor: token('color.skeleton', N20A),
			borderRadius: '100%',
			flexShrink: 0,
			content: '""',
			marginLeft: 0,
			marginRight: token('space.150', '12px'),
			width: ICON_SIZE,
			height: ICON_SIZE,
		},
	};
};

export default function LoadingSkeletonItem() {
	return (
		// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
		<SkeletonItem hasAvatar cssFn={cssFn} testId="people-menu-skeleton-item" />
	);
}
