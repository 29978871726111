import React, { useCallback, useContext } from 'react';

import { useIntl } from 'react-intl-next';

import { withAnalyticsEvents, type WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { LinkItem } from '@atlaskit/menu';
import {
	withMandatoryPeopleMenuConfiguration,
	type WithMandatoryPeopleMenuConfigurationProps,
} from '@atlassian/people-teams-configuration-client/people-menu';
import type { TeamV4WithAvatarImage, TeamWithAvatarImage } from '@atlassian/people-teams/types';
import { getTeamProfilePageLinkWithOrgId } from '@atlassian/teams-common/urls';

import { PeopleMenuContext, usePeopleMenuContext } from '../../context/PeopleMenuContext';
import { VOID_FUNC } from '../../utils/constants';
import { isModifiedEvent } from '../../utils/keys';
import { AnalyticsActionSubject, triggerAnalyticsForClickOnTeam } from '../analytics';
import { messages } from '../i18n';

import { MenuGroupSection } from './MenuGroupSection';
import { ImgIcon } from './utils';

interface PeopleMenuGroupProps {
	teams?: TeamWithAvatarImage[] | TeamV4WithAvatarImage[];
	isLoading?: boolean;
	isLoadingPermission?: boolean;
}

type MenuGroupTeamsInternalProps = PeopleMenuGroupProps &
	WithAnalyticsEventsProps &
	WithMandatoryPeopleMenuConfigurationProps;

export const MenuGroupTeamsInternal = (props: MenuGroupTeamsInternalProps) => {
	const intl = useIntl();
	const { orgId } = usePeopleMenuContext();

	const { teams = [], createAnalyticsEvent, isLoading, remoteConfig } = props;

	const {
		testId,
		product,
		onClickedItem = VOID_FUNC,
		pushRoute = VOID_FUNC,
		togglePeopleMenu = VOID_FUNC,
	} = useContext(PeopleMenuContext);

	const handleClickOnTeam = useCallback(
		(id: string) => (event: React.MouseEvent | React.KeyboardEvent) => {
			const isLeftClick = !isModifiedEvent(event);

			triggerAnalyticsForClickOnTeam(createAnalyticsEvent, isLeftClick);
			onClickedItem(id, 'team', event);

			if (isLeftClick) {
				const teamUrl = getTeamProfilePageLinkWithOrgId({
					remoteConfigResources: remoteConfig.resources,
					teamId: id,
					orgId,
					ref: product,
					src: AnalyticsActionSubject.PEOPLE_MENU,
				});
				pushRoute(teamUrl);
				event.preventDefault();
			}

			togglePeopleMenu(false);
		},
		[
			createAnalyticsEvent,
			onClickedItem,
			orgId,
			product,
			pushRoute,
			remoteConfig.resources,
			togglePeopleMenu,
		],
	);

	if (!remoteConfig.sections.teams.enabled) {
		return null;
	}

	return (
		<MenuGroupSection
			title={remoteConfig.sections.teams.label ?? intl.formatMessage(messages.yourTeams)}
			testId={`${testId}-teams-section`}
			isLoading={isLoading}
			isEmpty={teams.length === 0}
			sectionType={'team'}
			maxItems={remoteConfig.sections.teams.maxItems}
		>
			{teams
				.slice(0, remoteConfig.sections.teams.maxItems)
				.map((team: TeamWithAvatarImage | TeamV4WithAvatarImage) => (
					<LinkItem
						key={team.id}
						href={getTeamProfilePageLinkWithOrgId({
							remoteConfigResources: remoteConfig.resources,
							teamId: team.id,
							orgId,
						})}
						onClick={handleClickOnTeam(team.id)}
						iconBefore={
							<ImgIcon
								src={team.smallAvatarImageUrl || team.smallHeaderImageUrl}
								alt={team.displayName}
							/>
						}
						description={intl.formatMessage(messages.numMembers, {
							memberCount: team.memberCount ?? 1,
						})}
						testId={`${testId}-team-item`}
					>
						{team.displayName}
					</LinkItem>
				))}
		</MenuGroupSection>
	);
};

export const MenuGroupTeams = withAnalyticsEvents()(
	withMandatoryPeopleMenuConfiguration<PeopleMenuGroupProps & WithAnalyticsEventsProps>(
		MenuGroupTeamsInternal,
	),
);
