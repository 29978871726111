export const FETCH_ERROR_NAME = 'FetchError';

export type ErrorWithStatus = Error & {
	status: number;
	traceId?: string;
};

export function enrichFetchError(error: Error, status: number, traceId?: string): ErrorWithStatus {
	return {
		name: FETCH_ERROR_NAME,
		message: error.message,
		stack: error.stack,
		status,
		traceId,
	};
}

export const fetchJson = <T>(url: string, init?: RequestInit): Promise<T> => {
	return fetch(url, { credentials: 'include', ...init }).then((response) => {
		if (response.ok) {
			return response.json();
		}
		const traceId: string | null | undefined =
			(response as Response | undefined)?.headers?.get('atl-traceid') || undefined;

		throw enrichFetchError(
			new Error(`Unable to fetch ${url} ${response.status} ${response.statusText}`),
			response.status,
			traceId,
		);
	});
};

export const postJson = <T>(url: string, data: any, headers?: { 'G-Recaptcha-Token': string }) =>
	fetchJson<T>(url, {
		method: 'POST',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
			...headers,
		},
		body: JSON.stringify(data),
	});
