import React, { type PropsWithChildren } from 'react';

import { AnalyticsContext } from '@atlaskit/analytics-next';
import { LazyIntlProvider } from '@atlassian/teams-common/i18n';
import { trimLastForwardSlash } from '@atlassian/teams-common/urls';

import { PeopleMenuContextProvider } from '../../context/PeopleMenuContext';
import { type PeopleMenuProviderProps } from '../../types';
import { packageContext } from '../../utils/package-context';
import { AnalyticsSource } from '../analytics';
import { fetchLanguageFile } from '../i18n/utils';

const defaultProps: Partial<PeopleMenuProviderProps> = {
	onClickedMenuLink: () => Promise.reject('No handler defined. Using default props value.'),
	testId: 'menu',
	isSSR: false,
};

/**
 * There are some essential higher components wrapping an actual PeopleMenu component:
 * - PeopleMenuContext: is used to avoid passing common props like cloudId
 * - IntlProvider: to make i18n working
 * - AnalyticsContext: is used to avoid passing many props for triggering analytics events
 * - ErrorBoundary: to capture any errors inside actual PeopleMenu component
 */
const PeopleMenuProviders = (props: PropsWithChildren<PeopleMenuProviderProps>) => {
	const {
		children,
		product,
		subProduct,
		cloudId,
		orgId,
		userId,
		isBrowseUsersEnabled,
		testId,
		tenantUrl,
		onClickedItem,
		onClickedMenuLink,
		invitePeopleHandlers,
		onOpen,
		onClose,
		pushRoute,
		_hasError,
		addFlag,
		isOpen,
		isSSR,
		teamCreateDialogProps,
		renotifyExperimentProps,
		shouldRenderToParent,
		isSectionList,
	} = {
		...defaultProps,
		...props,
	};

	const analyticsContextData = {
		product,
		source: AnalyticsSource.PEOPLE_MENU,
		componentName: AnalyticsSource.PEOPLE_MENU,
		...packageContext,
	};

	const commonPropsInContext = {
		cloudId,
		orgId,
		product,
		subProduct,
		userId,
		isBrowseUsersEnabled,
		isSSR,
		testId,
		tenantUrl: trimLastForwardSlash(tenantUrl),
		onClickedItem,
		onClickedMenuLink,
		invitePeopleHandlers,
		onOpen,
		onClose,
		pushRoute,
		_hasError,
		addFlag,
		isPeopleMenuOpen: false,
		isOpen,
		teamCreateDialogProps,
		renotifyExperimentProps,
		shouldRenderToParent,
		isSectionList,
	};

	return (
		<PeopleMenuContextProvider {...commonPropsInContext}>
			<LazyIntlProvider fetchLanguageFile={fetchLanguageFile}>
				<AnalyticsContext data={analyticsContextData}>{children}</AnalyticsContext>
			</LazyIntlProvider>
		</PeopleMenuContextProvider>
	);
};

export default PeopleMenuProviders;
