import { defineMessages } from 'react-intl-next';

const PREFIX = 'ptc.pm';

export const messages = defineMessages({
	yourCollab: {
		id: `${PREFIX}.collaborators.title`,
		defaultMessage: 'Your Collaborators',
		description: 'A title of Your-Collaborators section in People Menu dropdown',
	},
	yourTeams: {
		id: `${PREFIX}.your-teams.title`,
		defaultMessage: 'Your Teams',
		description: 'A title of a Your-Teams section in People Menu dropdown',
	},
	viewYourProfile: {
		id: `${PREFIX}.view-your-profile`,
		defaultMessage: 'View your profile',
		description:
			'A link in People Menu. Clicking on it will navigate the user to their personal profile',
	},
	errorHeading: {
		id: `${PREFIX}.error-heading`,
		defaultMessage: 'Something’s gone wrong',
		description:
			'Heading of the error screen which is shown when an unknown error happens in the People Menu. Usually due to failed network requests.',
	},
	errorText: {
		id: `${PREFIX}.error-text`,
		defaultMessage:
			'We keep track of these errors, but feel free to contact us if refreshing doesn’t fix things',
		description: 'Text that is displayed when an unknown error happens in the People Menu.',
	},
	errorImageAltText: {
		id: `${PREFIX}.error-image-alt-text`,
		defaultMessage: 'A broken robot and a number of people busy fixing it.',
		description: 'Text displayed as alt text when an error occurs in the People Menu',
	},
	people: {
		id: `${PREFIX}.people`,
		defaultMessage: 'People',
		description: 'A default title of primary People menu',
	},
	teams: {
		id: `${PREFIX}.teams`,
		defaultMessage: 'Teams',
		description: 'A new default title of primary People menu',
	},
	numMembers: {
		id: `${PREFIX}.num-members`,
		defaultMessage: `{memberCount, plural,
    one {# member}
    other {# members}
}`,
		description: 'Subtext of the number of members belonging to a team',
	},
});
