import React from 'react';

import PeopleMenu from '@atlassian/people-menu';

import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

// useSSRPlaceholderReplaceIdProp is added to LOADABLE AFTER_PAINT priority elements to identify and exclude them
// from TTVC calculations if their size and position doesn't change after hydration

// adding loadable placeholderId around PeopleMenu here to avoid adding it within PeopleMenu (AtlasKit component)
export const PeopleItemWrapper = (props) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	return (
		<div data-vc="app-navigation-people-menu" {...ssrPlaceholderIdProp}>
			<PeopleMenu {...props} />
		</div>
	);
};
