import { type CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import {
	fireOperationalEvent,
	fireScreenEvent,
	fireTrackEvent,
	fireUIEvent,
} from '@atlassian/teams-common/analytics';

import { AnalyticsAction, AnalyticsActionSubject, AnalyticsSource } from './constants';

export { triggerAnalyticsForLoadedPeople, triggerAnalyticsForLoadedTeam } from './slo-events';

export { AnalyticsActionSubject, AnalyticsAction, AnalyticsSource };

export const triggerAnalyticsForClickCreateNewTeam = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
) => {
	fireUIEvent(createAnalyticsEvent, {
		actionSubject: AnalyticsActionSubject.CREATE_NEW_TEAM_LINK,
		action: AnalyticsAction.CLICKED,
	});
};

export const triggerAnalyticsForMenuLink = (
	additionalLinkId: string,
	action: AnalyticsAction,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	attributes?: {
		product?: string;
		error?: any;
	},
) => {
	fireUIEvent(createAnalyticsEvent, {
		actionSubject: AnalyticsActionSubject.VIEW_PEOPLE_ADDITIONAL_LINK,
		actionSubjectId: additionalLinkId,
		action,
		attributes: {
			integration: 'peopleMenu',
			...attributes,
		},
	});
};

export const triggerAnalyticsForClickViewDirectory = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	isLeftClick: boolean = false,
) => {
	fireUIEvent(createAnalyticsEvent, {
		actionSubject: AnalyticsActionSubject.VIEW_PEOPLE_DIRECTORY_LINK,
		action: AnalyticsAction.CLICKED,
		attributes: {
			isLeftClick,
		},
	});
};

export const triggerAnalyticsForClickInvitePeople = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	attributes?: {
		product?: string;
	},
) => {
	fireUIEvent(createAnalyticsEvent, {
		actionSubject: AnalyticsActionSubject.NAVIGATION_MENU_ITEM,
		actionSubjectId: 'addPeopleNavigationMenuItem',
		action: AnalyticsAction.CLICKED,
		attributes: {
			integration: 'peopleMenu',
			...attributes,
		},
	});
};

export const triggerAnalyticsForClickOnPeople = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	isLeftClick: boolean = false,
) => {
	fireUIEvent(createAnalyticsEvent, {
		actionSubject: AnalyticsActionSubject.PEOPLE_MENU_LINK,
		action: AnalyticsAction.CLICKED,
		attributes: {
			isLeftClick,
		},
	});
};

export const triggerAnalyticsForClickOnTeam = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	isLeftClick: boolean = false,
) => {
	fireUIEvent(createAnalyticsEvent, {
		actionSubject: AnalyticsActionSubject.TEAM_MENU_LINK,
		action: AnalyticsAction.CLICKED,
		attributes: {
			isLeftClick,
		},
	});
};

export const triggerAnalyticsForViewedPeopleMenu = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	isCacheEmpty = false,
) => {
	fireScreenEvent(createAnalyticsEvent, {
		name: 'peopleMenu',
		attributes: {
			isCacheEmpty,
		},
	});
};

export const triggerAnalyticsForRenderedInvitePeople = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
) => {
	fireUIEvent(createAnalyticsEvent, {
		actionSubject: 'addPeopleNavigationItem',
		action: AnalyticsAction.RENDERED,
	});
};

export const triggerAnalyticsForPreFetchData = (createAnalyticsEvent?: CreateUIAnalyticsEvent) => {
	fireTrackEvent(createAnalyticsEvent, {
		actionSubject: AnalyticsActionSubject.PRE_FETCH_DATA,
		action: AnalyticsAction.TRIGGERED,
	});
};

export const triggerAnalyticsForLoadingIndicator = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	duration = 0,
	startTime = 0,
) => {
	fireUIEvent(createAnalyticsEvent, {
		actionSubject: AnalyticsActionSubject.PEOPLE_MENU,
		actionSubjectId: 'loadingIndicator',
		action: AnalyticsAction.VIEWED,
		attributes: {
			duration,
			startTime,
		},
	});
};

export const triggerAnalyticsForFetchingData = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	duration = 0,
	startTime = 0,
) => {
	fireTrackEvent(createAnalyticsEvent, {
		actionSubject: AnalyticsActionSubject.FETCHING_USERS_TEAMS_DATA,
		action: AnalyticsAction.MEASURED,
		attributes: {
			duration,
			startTime,
		},
	});
};

export const triggerAnalyticsForHoverAndClick = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	duration = 0,
	startTime = 0,
) => {
	fireTrackEvent(createAnalyticsEvent, {
		actionSubject: AnalyticsActionSubject.HOVER_AND_CLICK_PEOPLE_BUTTON,
		action: AnalyticsAction.MEASURED,
		attributes: {
			duration,
			startTime,
		},
	});
};

export const triggerAnalyticsForViewedFullUsersAndTeams = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	usersCount = 0,
	teamsCount = 0,
	duration = 0,
	startTime = 0,
) => {
	fireTrackEvent(createAnalyticsEvent, {
		actionSubject: AnalyticsActionSubject.PEOPLE_MENU,
		action: AnalyticsAction.SUCCEEDED,
		attributes: {
			usersCount,
			teamsCount,
			duration,
			startTime,
		},
	});
};

export const triggerAnalyticsForNoBrowsePeoplePermission = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
) => {
	fireUIEvent(createAnalyticsEvent, {
		actionSubject: AnalyticsActionSubject.PEOPLE_MENU,
		action: AnalyticsAction.VIEWED,
		actionSubjectId: 'noBrowsePermission',
	});
};

export const triggerAnalyticsForRenotifyMessageNotNow = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
) => {
	fireUIEvent(createAnalyticsEvent, {
		actionSubject: AnalyticsActionSubject.BUTTON,
		action: AnalyticsAction.CLICKED,
		actionSubjectId: 'dismissButton',
		source: AnalyticsSource.RENOTIFY_MESSAGE,
	});
};

export const triggerAnalyticsForRenotifyMessageRemindTeam = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
) => {
	fireUIEvent(createAnalyticsEvent, {
		actionSubject: AnalyticsActionSubject.BUTTON,
		action: AnalyticsAction.CLICKED,
		actionSubjectId: 'remindTeamMembersButton',
		source: AnalyticsSource.RENOTIFY_MESSAGE,
	});
};

export const triggerAnalyticsForRenotifyMessageViewed = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
) => {
	fireScreenEvent(createAnalyticsEvent, {
		action: AnalyticsAction.VIEWED,
		name: AnalyticsSource.RENOTIFY_MESSAGE,
	});
};

export const triggerAnalyticsForRenotifyModalViewed = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
) => {
	fireScreenEvent(createAnalyticsEvent, {
		action: AnalyticsAction.VIEWED,
		name: AnalyticsSource.RENOTIFY_MODAL,
	});
};

export const triggerAnalyticsForRenotifyModalCopyLink = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	attributes?: {
		originIdGenerated?: string;
		originProduct?: string;
		originSubproduct?: string;
	},
) => {
	fireUIEvent(createAnalyticsEvent, {
		actionSubject: AnalyticsActionSubject.BUTTON,
		action: AnalyticsAction.CLICKED,
		actionSubjectId: 'copyInviteLinkButton',
		source: AnalyticsSource.RENOTIFY_MODAL,
		attributes,
	});
};

export const triggerAnalyticsForRenotifyModalCancelButton = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
) => {
	fireUIEvent(createAnalyticsEvent, {
		actionSubject: AnalyticsActionSubject.BUTTON,
		action: AnalyticsAction.CLICKED,
		actionSubjectId: 'dismissButton',
		source: AnalyticsSource.RENOTIFY_MODAL,
	});
};

export const triggerAnalyticsForRenotifyModalSendReminderButton = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	attributes?: {
		originIdGenerated?: string;
		originProduct?: string;
		originSubproduct?: string;
		numberOfInviteeEmails?: number;
	},
) => {
	fireUIEvent(createAnalyticsEvent, {
		actionSubject: AnalyticsActionSubject.BUTTON,
		action: AnalyticsAction.CLICKED,
		actionSubjectId: 'sendReminderButton',
		source: AnalyticsSource.RENOTIFY_MODAL,
		attributes,
	});
};

export const triggerAnalyticsForRenotifyFetchInactiveUsersSucceeded = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	attributes?: {
		numberOfInviteeEmails?: number;
	},
) => {
	fireOperationalEvent(createAnalyticsEvent, {
		actionSubject: AnalyticsActionSubject.FETCH_INACTIVE_USERS_DATA,
		action: AnalyticsAction.SUCCEEDED,
		source: AnalyticsSource.RENOTIFY_MODAL,
		attributes,
	});
};

export const triggerAnalyticsForRenotifyFetchInactiveUsersFailed = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
) => {
	fireOperationalEvent(createAnalyticsEvent, {
		actionSubject: AnalyticsActionSubject.FETCH_INACTIVE_USERS_DATA,
		action: AnalyticsAction.FAILED,
		source: AnalyticsSource.RENOTIFY_MODAL,
	});
};

export const triggerAnalyticsForRenotifySendInvitesSucceeded = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	attributes?: {
		numberOfInviteeEmails?: number;
	},
) => {
	fireOperationalEvent(createAnalyticsEvent, {
		actionSubject: AnalyticsActionSubject.SEND_REINVITES,
		action: AnalyticsAction.SUCCEEDED,
		source: AnalyticsSource.RENOTIFY_MODAL,
		attributes,
	});
};

export const triggerAnalyticsForRenotifySendInvitesFailed = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	attributes?: {
		numberOfInviteeEmails?: number;
	},
) => {
	fireOperationalEvent(createAnalyticsEvent, {
		actionSubject: AnalyticsActionSubject.SEND_REINVITES,
		action: AnalyticsAction.FAILED,
		source: AnalyticsSource.RENOTIFY_MODAL,
		attributes,
	});
};

export const triggerAnalyticsForRenotifyNotEligible = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	attributes?: {
		reason?: string;
	},
) => {
	fireTrackEvent(createAnalyticsEvent, {
		actionSubject: AnalyticsActionSubject.RENOTIFY_EXPERIMENT,
		action: AnalyticsAction.INELIGIBLE,
		source: AnalyticsSource.RENOTIFY_MESSAGE,
		attributes,
	});
};

export const triggerAnalyticsForRenotifySpotlightViewed = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
) => {
	fireScreenEvent(createAnalyticsEvent, {
		action: AnalyticsAction.VIEWED,
		name: AnalyticsSource.RENOTIFY_SPOTLIGHT,
	});
};

export const triggerAnalyticsForRenotifySpotlightCancelButton = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
) => {
	fireUIEvent(createAnalyticsEvent, {
		actionSubject: AnalyticsActionSubject.BUTTON,
		action: AnalyticsAction.CLICKED,
		actionSubjectId: 'renotifySpotlightDismissButton',
		source: AnalyticsSource.RENOTIFY_SPOTLIGHT,
	});
};

export const triggerAnalyticsForRenotifySpotlightConfirmButton = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
) => {
	fireUIEvent(createAnalyticsEvent, {
		actionSubject: AnalyticsActionSubject.BUTTON,
		action: AnalyticsAction.CLICKED,
		actionSubjectId: 'renotifySpotlightConfirmButton',
		source: AnalyticsSource.RENOTIFY_SPOTLIGHT,
	});
};
