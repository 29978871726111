import { useEffect, useState } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	triggerAnalyticsForRenotifyFetchInactiveUsersFailed,
	triggerAnalyticsForRenotifyFetchInactiveUsersSucceeded,
	triggerAnalyticsForRenotifyNotEligible,
} from '../components/analytics';
import type { ErrorWithStatus, InactiveUser, Product, RenotifyExperimentProps } from '../types';

import { usePendingUsersService } from './usePendingUsersService';

export const mapDataCache = new Map();
export const PENDING_USERS_CACHE_KEY = 'pendingUsers';
export const RENOTIFY_ELIGIBILITY_CACHE_KEY = 'renotifyEligibility';

export enum RenotifyStatus {
	IS_LOADING = 'isLoading',
	ELIGIBLE = 'eligible',
	INELIGIBLE = 'ineligible',
	ERROR = 'error',
}

export type PeopleMenuData =
	| {
			status: RenotifyStatus.IS_LOADING;
			isLoading: true;
	  }
	| {
			status: RenotifyStatus.ERROR;
			pendingUsersError: ErrorWithStatus;
			isLoading: false;
	  }
	| {
			status: RenotifyStatus.INELIGIBLE;
			isLoading: false;
	  }
	| {
			status: RenotifyStatus.ELIGIBLE;
			isLoading: false;
			pendingUsers: InactiveUser[];
	  };

const REQUEST_CACHE_TIMEOUT_PENDING_USERS = 10 * 60 * 1000; // 10 minutes

export function usePendingUsers({
	cloudId,
	startFetching,
	renotifyExperiment,
	product,
	requestCacheTimeout,
}: {
	cloudId: string;
	startFetching: boolean;
	renotifyExperiment?: RenotifyExperimentProps;
	product?: Product;
	requestCacheTimeout?: number;
}): PeopleMenuData {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [pendingUsers, setPendingUsers] = useState<InactiveUser[] | undefined>(
		mapDataCache.get(PENDING_USERS_CACHE_KEY),
	);
	const [renotifyEligible, setRenotifyEligibility] = useState<boolean | undefined>(
		mapDataCache.get(RENOTIFY_ELIGIBILITY_CACHE_KEY),
	);

	const {
		loading: pendingUsersLoading,
		error: pendingUsersError,
		data: pendingUsersData,
		fetchData: fetchPendingUsers,
	} = usePendingUsersService(
		cloudId,
		requestCacheTimeout === undefined ? REQUEST_CACHE_TIMEOUT_PENDING_USERS : requestCacheTimeout,
		product,
	);

	useEffect(() => {
		if (renotifyEligible !== undefined || renotifyExperiment?.isLoading || !startFetching) {
			return;
		}

		if (renotifyExperiment?.isEnrolled) {
			fetchPendingUsers();
		} else {
			mapDataCache.set(RENOTIFY_ELIGIBILITY_CACHE_KEY, false);
			setRenotifyEligibility(false);
		}
	}, [
		renotifyEligible,
		fetchPendingUsers,
		renotifyExperiment?.isLoading,
		renotifyExperiment?.isEnrolled,
		startFetching,
	]);

	useEffect(() => {
		if (pendingUsersError) {
			triggerAnalyticsForRenotifyFetchInactiveUsersFailed(createAnalyticsEvent);
			mapDataCache.set(RENOTIFY_ELIGIBILITY_CACHE_KEY, false);
			setRenotifyEligibility(false);
			return;
		}

		if (pendingUsersData === undefined || pendingUsersLoading) {
			return;
		}
		mapDataCache.set(PENDING_USERS_CACHE_KEY, pendingUsersData);

		setPendingUsers(pendingUsersData);

		if (pendingUsersData?.length) {
			triggerAnalyticsForRenotifyFetchInactiveUsersSucceeded(createAnalyticsEvent, {
				numberOfInviteeEmails: pendingUsersData?.length,
			});
			mapDataCache.set(RENOTIFY_ELIGIBILITY_CACHE_KEY, true);
			if (renotifyExperiment?.isEnrolled) {
				setRenotifyEligibility(true);
			}
		} else {
			triggerAnalyticsForRenotifyNotEligible(createAnalyticsEvent, {
				reason: 'No pending users',
			});
			mapDataCache.set(RENOTIFY_ELIGIBILITY_CACHE_KEY, false);
			setRenotifyEligibility(false);
		}
	}, [
		createAnalyticsEvent,
		pendingUsersData,
		pendingUsersError,
		pendingUsersLoading,
		renotifyExperiment,
	]);

	if (pendingUsersLoading || renotifyExperiment?.isLoading) {
		return {
			status: RenotifyStatus.IS_LOADING,
			isLoading: true,
		};
	}

	if (pendingUsersError) {
		return {
			status: RenotifyStatus.ERROR,
			pendingUsersError: pendingUsersError ?? new Error('Invalid pending user response'),
			isLoading: false,
		};
	}

	if (renotifyEligible === false || !pendingUsers) {
		return {
			status: RenotifyStatus.INELIGIBLE,
			isLoading: false,
		};
	}

	return {
		status: RenotifyStatus.ELIGIBLE,
		isLoading: false,
		pendingUsers,
	};
}
