type ErrorContext = {
	orgId?: string;
	cloudId?: string;
	userId?: string;
	product?: string;
	tenantUrl?: string;
};

export class ErrorWithContext extends Error {
	context: ErrorContext;
	constructor(message: string, context: ErrorContext) {
		super(message);
		this.context = context;
	}
}
