import { useCallback } from 'react';

import {
	DEFAULT_RELEASE_RESOLVED_PROMISE_DELAY,
	withCached,
} from '@atlassian/people-teams/helpers';
import { useService } from '@atlassian/people-teams/hooks';

import { fetchInactiveUsers } from '../clients/experimentApiClient';
import { usePeopleMenuContext } from '../context/PeopleMenuContext';
import { type InactiveUser, type InactiveUsersResponse, type Product } from '../types';

export interface UseRenotifyStatusCheckData {
	data?: InactiveUser[];
	fetchData: () => Promise<InactiveUsersResponse>;
	error?: Error;
	loading?: boolean;
}

const mapFetchInactiveUserFnByTimeout = new Map<
	number,
	((cloudId: string, product: string) => Promise<InactiveUsersResponse>) | undefined
>();

function getFetchInactiveUsersCachedFn(timeout = DEFAULT_RELEASE_RESOLVED_PROMISE_DELAY) {
	let fn = mapFetchInactiveUserFnByTimeout.get(timeout);

	if (fn) {
		return fn;
	}

	fn = withCached(fetchInactiveUsers, timeout);
	mapFetchInactiveUserFnByTimeout.set(timeout, fn);
	return fn;
}

export function usePendingUsersService(
	cloudId: string,
	cacheTimeout?: number,
	product?: Product,
): UseRenotifyStatusCheckData {
	const { product: contextProduct } = usePeopleMenuContext();
	const request = useCallback((): Promise<InactiveUsersResponse> => {
		if (cacheTimeout) {
			const fn = getFetchInactiveUsersCachedFn(cacheTimeout);
			return fn(cloudId, product ?? contextProduct);
		}
		return fetchInactiveUsers(cloudId, product ?? contextProduct);
	}, [cacheTimeout, cloudId, product, contextProduct]);

	const { loading, error, data, fetchData } = useService(request);

	return {
		loading,
		error,
		data: data?.users,
		fetchData,
	};
}
