import type { Product } from '../../types';

import { type PeopleAndTeamsMenuResources } from './types';

export interface OriginQuery {
	cloudId: string;
	product?: Product;
}

type NullablePeopleAndTeamsMenuConfigurationResources = PeopleAndTeamsMenuResources | null;

function toStrParams(obj: Record<string, string | undefined>) {
	const str = Object.keys(obj)
		.map((k) => {
			const v = obj[k];
			return v ? `${k}=${encodeURIComponent(v)}` : undefined;
		})
		.filter((it) => !!it)
		.join('&');

	return str ? `?${str}` : '';
}

export function constructProductOriginParams(originQuery: OriginQuery): string {
	return toStrParams({
		'origin.cloudId': originQuery.cloudId,
		'origin.product': originQuery.product?.toUpperCase() || '',
	});
}

export function getContextPathFromProduct(
	remoteConfigResources: NullablePeopleAndTeamsMenuConfigurationResources,
) {
	return remoteConfigResources?.baseUrl || '';
}

export function getPeopleDirectoryHomePageWithOrgId({
	remoteConfigResources,
	orgId,
	ref,
	src,
}: {
	remoteConfigResources: NullablePeopleAndTeamsMenuConfigurationResources;
	orgId?: string;
	ref?: string;
	src?: string;
}): string {
	const params = {
		ref,
		src,
	};

	return `${getContextPathFromProduct(remoteConfigResources)}${(
		remoteConfigResources?.peopleDirectoryHome || ''
	).replace(':orgId', orgId ?? '')}${toStrParams(params)}`;
}

/**
 * @deprecated use getPeopleDirectoryHomePageWithOrgId
 */
export function getPeopleDirectoryHomePage(
	remoteConfigResources: NullablePeopleAndTeamsMenuConfigurationResources,
	ref?: string,
	src?: string,
): string {
	const params = {
		ref,
		src,
	};

	return `${getContextPathFromProduct(remoteConfigResources)}${
		remoteConfigResources?.peopleDirectoryHome || ''
	}${toStrParams(params)}`;
}

export function getPeopleProfilePage(
	remoteConfigResources: NullablePeopleAndTeamsMenuConfigurationResources,
	userId: string,
	ref?: string,
	src?: string,
): string {
	const params = {
		ref,
		src,
	};

	return `${getContextPathFromProduct(remoteConfigResources)}${(
		remoteConfigResources?.peopleProfile || ''
	).replace(':userId', userId)}${toStrParams(params)}`;
}

export function getTeamProfilePageLinkWithOrgId({
	remoteConfigResources,
	teamId,
	orgId,
	ref,
	src,
}: {
	remoteConfigResources: NullablePeopleAndTeamsMenuConfigurationResources;
	teamId: string;
	orgId?: string;
	ref?: string;
	src?: string;
}) {
	const params = {
		ref,
		src,
	};

	return `${getContextPathFromProduct(remoteConfigResources)}${(
		remoteConfigResources?.teamProfile || ''
	)
		.replace(':teamId', teamId)
		.replace(':orgId', orgId ?? '')}${toStrParams(params)}`;
}

/** @deprecated use getTeamProfilePageLinkWithOrgId */
export function getTeamProfilePage(
	remoteConfigResources: NullablePeopleAndTeamsMenuConfigurationResources,
	teamId: string,
	ref?: string,
	src?: string,
): string {
	const params = {
		ref,
		src,
	};

	return `${getContextPathFromProduct(remoteConfigResources)}${(
		remoteConfigResources?.teamProfile || ''
	).replace(':teamId', teamId)}${toStrParams(params)}`;
}

export function trimLastForwardSlash(url: string = '') {
	if (url[url.length - 1] === '/') {
		return url.slice(0, -1);
	}

	return url;
}
