import React, { lazy, Suspense, useCallback, useContext, useMemo, useState } from 'react';

import { LazySuspense } from 'react-loosely-lazy';

import { Box, xcss } from '@atlaskit/primitives';
import { type TeamCreateDialogProps } from '@atlassian/people-teams';

import { AnalyticsSource } from '../components/analytics';
import { RenotifyModal } from '../components/PeopleMenu/lazy-renotify-modal';
import { RenotifyStatus, usePendingUsers } from '../hooks/usePendingUsers';
import { type PeopleMenuContextProps } from '../types';

const AKModalTransition = lazy(() =>
	import(
		/* webpackChunkName: "async-people-menu-modal-transition-dialog" */ '@atlaskit/modal-dialog'
	).then((module) => ({
		default: module.ModalTransition,
	})),
);

const TeamCreateDialog = lazy(() =>
	import(
		/* webpackChunkName: "async-people-menu-team-create-dialog" */ '@atlassian/people-teams/teamCreateDialog'
	).then((module) => ({
		default: module.TeamCreateDialog,
	})),
);

// this wrapper is for preventing changing layout of around elements
const DialogWrapper = xcss({
	width: 'space.0',
	height: 'space.0',
	margin: '0',
	padding: '0',
	'::before': {
		width: 'space.0',
		height: 'space.0',
		margin: '0',
		padding: '0',
	},
	'::after': {
		width: 'space.0',
		height: 'space.0',
		margin: '0',
		padding: '0',
	},
});

const analyticsAttrsTeamCreateDialog = {
	ref: AnalyticsSource.PEOPLE_MENU,
};

export const PeopleMenuContext = React.createContext<PeopleMenuContextProps>({
	orgId: '',
	cloudId: '',
	userId: '',
	product: 'confluence',
	testId: '',
	isPeopleMenuOpen: false,
	isSSR: false,
	onClickedItem: () => undefined,
	pushRoute: () => undefined,
	togglePeopleMenu: () => undefined,
	toggleTeamCreateDialog: () => undefined,
	_hasError: undefined,
	teamCreateDialogProps: {},
	renotifyExperimentProps: {
		isLoading: false,
		isEnrolled: false,
	},
	showRenotifyMessage: false,
	shouldRenderToParent: false,
	isSectionList: false,
});

interface Props {
	children: React.ReactNode;
	isOpen: boolean;
	onClose: () => void;
	onOpen: () => void;
}

export function PeopleMenuContextProvider(props: Props & PeopleMenuContextProps) {
	const { isSSR = false, cloudId, renotifyExperimentProps } = props;
	const [isOpenTeamCreateDialog, toggleOpenTeamCreateDialog] = useState(false);
	const [renotifyDismissed, setRenotifyDismissed] = useState(false);
	const [isSpotlightActive, setIsSpotlightActive] = useState<boolean | undefined>(undefined);
	const [showRenotifyModal, setShowRenotifyModal] = useState(false);
	const { children, isOpen, onOpen, onClose, ...otherProps } = props;

	const pendingUsersResponse = usePendingUsers({
		cloudId,
		startFetching: isOpen,
		renotifyExperiment: renotifyExperimentProps,
		product: props?.product,
	});

	const renotifyEligible = pendingUsersResponse.status === RenotifyStatus.ELIGIBLE;
	const pendingUsers = renotifyEligible ? pendingUsersResponse.pendingUsers : undefined;

	const showRenotifyMessage = renotifyEligible && !renotifyDismissed;
	const togglePeopleMenu = useCallback(
		(newState: boolean) => {
			if (newState === isOpen) {
				return;
			}

			if (newState) {
				onOpen();
			} else {
				onClose();
			}
		},
		[isOpen, onClose, onOpen],
	);

	const toggleTeamCreateDialog = useCallback(
		(isOpen: boolean) => {
			toggleOpenTeamCreateDialog(isOpen);
		},
		[toggleOpenTeamCreateDialog],
	);

	const toggleRenotifyModal = useCallback(() => {
		setShowRenotifyModal((prevState) => {
			return !prevState;
		});
	}, []);

	const values = useMemo<PeopleMenuContextProps>(
		() => ({
			teamCreateDialogProps: {} as Partial<TeamCreateDialogProps>,
			...otherProps,
			// extra props
			isPeopleMenuOpen: isOpen,
			togglePeopleMenu,
			toggleTeamCreateDialog,
			toggleRenotifyModal,
			pendingUsers,
			renotifyEligible,
			showRenotifyMessage,
			setRenotifyDismissed,
			isSpotlightActive,
			setIsSpotlightActive,
		}),
		[
			otherProps,
			isOpen,
			togglePeopleMenu,
			toggleTeamCreateDialog,
			toggleRenotifyModal,
			pendingUsers,
			renotifyEligible,
			showRenotifyMessage,
			setRenotifyDismissed,
			isSpotlightActive,
			setIsSpotlightActive,
		],
	);

	const closeTeamCreateDialog = useCallback(() => {
		toggleOpenTeamCreateDialog(false);

		if (typeof values.teamCreateDialogProps?.onClose === 'function') {
			values.teamCreateDialogProps.onClose();
		}
	}, [toggleOpenTeamCreateDialog, values.teamCreateDialogProps]);

	return (
		<PeopleMenuContext.Provider value={values}>
			{children}
			{!isSSR && isOpenTeamCreateDialog && (
				<Box xcss={DialogWrapper} testId={`${otherProps.testId}-team-create-dialog-wrapper`}>
					<Suspense fallback={null}>
						<AKModalTransition>
							{isOpenTeamCreateDialog && (
								<TeamCreateDialog
									cloudId={values.cloudId}
									orgId={values.orgId}
									product={values.product}
									principalId={values.userId}
									addFlag={values.addFlag}
									pushRoute={values.pushRoute}
									extraAnalyticsAttrs={analyticsAttrsTeamCreateDialog}
									{...values.teamCreateDialogProps}
									onClose={closeTeamCreateDialog}
								/>
							)}
						</AKModalTransition>
					</Suspense>
				</Box>
			)}
			{!isSSR && showRenotifyModal && (
				<Box xcss={DialogWrapper}>
					<LazySuspense fallback={null}>
						<AKModalTransition>{showRenotifyModal && <RenotifyModal />}</AKModalTransition>
					</LazySuspense>
				</Box>
			)}
		</PeopleMenuContext.Provider>
	);
}

export const usePeopleMenuContext = () => useContext(PeopleMenuContext);
