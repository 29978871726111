// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { Section } from '@atlaskit/menu';
import { Y100 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RenotifyMessageContainer = styled(Section)({
	maxWidth: '300px',
	padding: token('space.200', '16px'),
	backgroundColor: token('color.background.accent.yellow.subtlest', Y100),
	'&::before': {
		height: token('space.0', '0px'),
	},
	'&::after': {
		height: token('space.0', '0px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RenotifyModalWidthFull = styled.div({
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RenotifyModalUsersContainer = styled.div({
	height: 'fit-content',
	maxHeight: '174px',
	overflowY: 'auto',
	marginTop: token('space.200', '16px'),
	paddingTop: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RenotifySpotlightTriggerChevron = styled.span({
	marginLeft: token('space.negative.100', '-8px'),
	marginRight: token('space.negative.100', '-8px'),
	opacity: 0.5,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RenotifySpotlightContent = styled.div({
	marginTop: token('space.050', '4px'),
	marginBottom: token('space.100', '8px'),
});
