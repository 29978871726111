import { type InactiveUsersResponse, type Reinvite, type ReinviteResponse } from '../types';
import { fetchJson, postJson } from '../utils/fetch';

const EXPERIMENT_API_BASE = '/gateway/api/growth/';

const getExperimentAPICommonPath = (product: string) =>
	`${EXPERIMENT_API_BASE}${
		product === 'confluence'
			? 'notify-if-invitee-never-logged-in-confluence'
			: 'notify-if-invitee-never-logged-in'
	}`;

const getInactiveUsersUrl = (cloudId: string, product: string) =>
	`${getExperimentAPICommonPath(product)}/get-inactive-users?cloudId=${cloudId}`;

export const fetchInactiveUsers = (
	cloudId: string,
	product: string,
): Promise<InactiveUsersResponse> => fetchJson(getInactiveUsersUrl(cloudId, product));

const getReinviteUrl = (product: string) => `${getExperimentAPICommonPath(product)}/reinvite`;

export const sendReinvites = (
	cloudId: string,
	reInvites: Reinvite[],
	product: string,
): Promise<ReinviteResponse> => {
	return postJson(getReinviteUrl(product), {
		owner: `ari:cloud:platform::site/${cloudId}`,
		reInvites: reInvites.map(({ userId, continueUrl }) => ({
			inviteeId: `ari:cloud:identity::user/${userId}`,
			continueUrl,
		})),
	});
};
