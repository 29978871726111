export enum AnalyticsActionSubject {
	ERROR_BOUNDARY = 'errorBoundary',
	VIEW_PEOPLE_ADDITIONAL_LINK = 'additionalMenuLink',
	VIEW_PEOPLE_DIRECTORY_LINK = 'viewAllPeopleDirectory',
	CREATE_NEW_TEAM_LINK = 'createNewTeamLink',
	PEOPLE_MENU_LINK = 'peopleMenuLink',
	TEAM_MENU_LINK = 'teamMenuLink',
	PEOPLE_MENU = 'peopleMenu',
	NAVIGATION_MENU_ITEM = 'navigationMenuItem',
	PRE_FETCH_DATA = 'preFetchData',
	FETCHING_USERS_TEAMS_DATA = 'fetchingUsersTeamsData',
	HOVER_AND_CLICK_PEOPLE_BUTTON = 'hoverAndClickPeopleButton',
	BUTTON = 'button',
	FETCH_INACTIVE_USERS_DATA = 'fetchInactiveUsersData',
	SEND_REINVITES = 'sendReinvites',
	RENOTIFY_EXPERIMENT = 'renotifyExperiment',
}

export enum AnalyticsAction {
	RENDERED = 'rendered',
	CLICKED = 'clicked',
	EXPOSED = 'exposed',
	FAILED = 'failed',
	SUCCEEDED = 'succeeded',
	VIEWED = 'viewed',
	TRIGGERED = 'triggered',
	MEASURED = 'measured',
	INELIGIBLE = 'ineligible',
}

export enum AnalyticsSource {
	PEOPLE_MENU = 'peopleMenu',
	RENOTIFY_MESSAGE = 'renotifyMessage',
	RENOTIFY_MODAL = 'renotifyModal',
	RENOTIFY_SPOTLIGHT = 'renotifySpotlight',
}
