/* istanbul ignore file */

export const fallback = () => Promise.resolve({ default: {} });

export const cs = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-cs" */ '../../i18n/cs');

export const da = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-da" */ '../../i18n/da');

export const de = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-de" */ '../../i18n/de');

export const en = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-en" */ '../../i18n/en');

export const engb = () =>
	import(
		/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-en-GB" */ '../../i18n/en_GB'
	);

export const enzz = () =>
	import(
		/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-en-ZZ" */ '../../i18n/en_ZZ'
	);

export const es = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-es" */ '../../i18n/es');

export const et = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-et" */ '../../i18n/et');

export const fi = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-fi" */ '../../i18n/fi');

export const fr = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-fr" */ '../../i18n/fr');

export const hu = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-hu" */ '../../i18n/hu');

export const is = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-is" */ '../../i18n/is');

export const it = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-it" */ '../../i18n/it');

export const ja = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-ja" */ '../../i18n/ja');

export const ko = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-ko" */ '../../i18n/ko');

export const nb = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-nb" */ '../../i18n/nb');

export const nl = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-nl" */ '../../i18n/nl');

export const pl = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-pl" */ '../../i18n/pl');

export const pt = () =>
	import(
		/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-pt-PT" */ '../../i18n/pt_PT'
	);

export const ptbr = () =>
	import(
		/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-pt-BR" */ '../../i18n/pt_BR'
	);

export const ro = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-ro" */ '../../i18n/ro');

export const ru = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-ru" */ '../../i18n/ru');

export const sk = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-sk" */ '../../i18n/sk');

export const sv = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-sv" */ '../../i18n/sv');

export const tr = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-tr" */ '../../i18n/tr');

export const th = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-th" */ '../../i18n/th');

export const uk = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-uk" */ '../../i18n/uk');

export const vi = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-vi" */ '../../i18n/vi');

export const zh = () =>
	import(/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-zh-ZH" */ '../../i18n/zh');

export const zhtw = () =>
	import(
		/* webpackChunkName: "@atlaskit-internal_async-people-menu-i18n-zh-TW" */ '../../i18n/zh_TW'
	);
