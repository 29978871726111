import React from 'react';

import { cssMap } from '@atlaskit/css';
import { Box } from '@atlaskit/primitives/compiled';
import { token } from '@atlaskit/tokens';

const styles = cssMap({
	wrapper: {
		display: 'flex',
		marginTop: token('space.025'), // Match @atlaskit.avatar
		marginRight: token('space.025'),
		marginBottom: token('space.025'),
		marginLeft: token('space.025'),
	},
});

export function FallbackAvatar(props: React.SVGProps<SVGSVGElement>) {
	return (
		<Box xcss={styles.wrapper}>
			<svg
				width="32"
				height="32"
				viewBox="0 0 32 32"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				{...props}
			>
				<g clipPath="url(#clip0_2032_552)">
					<rect width="32" height="32" rx="4" fill="#DCDFE4" />
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M10.6666 12.6666C11.7712 12.6666 12.6666 11.7712 12.6666 10.6666C12.6666 9.56206 11.7712 8.66663 10.6666 8.66663C9.56202 8.66663 8.66658 9.56206 8.66658 10.6666C8.66658 11.7712 9.56202 12.6666 10.6666 12.6666ZM10.6666 14.6666C12.8757 14.6666 14.6666 12.8758 14.6666 10.6666C14.6666 8.45749 12.8757 6.66663 10.6666 6.66663C8.45745 6.66663 6.66658 8.45749 6.66658 10.6666C6.66658 12.8758 8.45745 14.6666 10.6666 14.6666Z"
						fill="#44546F"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M21.3333 12.6666C22.4378 12.6666 23.3333 11.7712 23.3333 10.6666C23.3333 9.56206 22.4378 8.66663 21.3333 8.66663C20.2287 8.66663 19.3333 9.56206 19.3333 10.6666C19.3333 11.7712 20.2287 12.6666 21.3333 12.6666ZM21.3333 14.6666C23.5424 14.6666 25.3333 12.8758 25.3333 10.6666C25.3333 8.45749 23.5424 6.66663 21.3333 6.66663C19.1241 6.66663 17.3333 8.45749 17.3333 10.6666C17.3333 12.8758 19.1241 14.6666 21.3333 14.6666Z"
						fill="#44546F"
					/>
					<path
						d="M21.6666 16C24.4273 16.0004 26.6666 18.2385 26.6666 21V22.6666C26.6666 24.1394 25.4727 25.3333 23.9999 25.3333H20.135C18.0324 25.3333 16.1545 24.018 15.436 22.042L14.6843 19.9747C14.2531 18.7892 13.1264 18 11.8649 18H10.3333C8.6764 18 7.33325 19.3431 7.33325 21V22.6666C7.33325 23.0348 7.63173 23.3333 7.99992 23.3333H13.3333V25.3333H7.99992C6.52716 25.3333 5.33325 24.1394 5.33325 22.6666V21C5.33325 18.2385 7.57183 16 10.3333 16H11.8649C13.9674 16 15.8453 17.3153 16.5638 19.2913L17.3156 21.3585C17.7467 22.5441 18.8734 23.3333 20.135 23.3333H23.9999C24.3681 23.3333 24.6666 23.0348 24.6666 22.6666V21C24.6666 19.3437 23.3233 18.0004 21.6666 18H20.135V16H21.6666Z"
						fill="#44546F"
					/>
					<path
						d="M20.135 16C19.5972 16 18.7183 16.1407 18.0518 16.4564L18.908 18.2639C19.064 18.19 19.288 18.1205 19.537 18.0703C19.785 18.0203 20.0045 18 20.135 18V16Z"
						fill="#44546F"
					/>
				</g>
				<defs>
					<clipPath id="clip0_2032_552">
						<rect width="32" height="32" rx="4" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</Box>
	);
}
