import React, { useContext } from 'react';

import { Section, SkeletonHeadingItem } from '@atlaskit/menu';

import { PeopleMenuContext } from '../../context/PeopleMenuContext';

import LoadingSkeletonItem from './LoadingSkeletonItem';

interface MenuGroupSection {
	title: string;
	isEmpty: boolean;
	isLoading?: boolean;
	children: React.ReactNode;
	testId: string;
	maxItems: number;
	sectionType: 'team' | 'user';
}

export const MenuGroupSection = ({
	title,
	isEmpty,
	isLoading,
	children,
	testId,
	maxItems,
	sectionType,
}: MenuGroupSection) => {
	const { isSectionList = false } = useContext(PeopleMenuContext);

	if (!isLoading && isEmpty) {
		return null;
	}

	return (
		<Section
			// title is undefined if we are loading as we show the heading skeleton below
			title={isLoading ? undefined : title}
			testId={testId}
			isList={isSectionList}
		>
			{isLoading ? (
				<>
					<SkeletonHeadingItem testId={`${sectionType}-skeleton-heading`} />
					{Array(maxItems)
						.fill(undefined)
						.map((_, index) => (
							<LoadingSkeletonItem key={`${sectionType}-${index}`} />
						))}
				</>
			) : (
				children
			)}
		</Section>
	);
};
