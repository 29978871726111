import React, { useCallback, useContext } from 'react';

import { useIntl } from 'react-intl-next';

import { withAnalyticsEvents, type WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { LinkItem } from '@atlaskit/menu';
import {
	withMandatoryPeopleMenuConfiguration,
	type WithMandatoryPeopleMenuConfigurationProps,
} from '@atlassian/people-teams-configuration-client/people-menu';
import type { User } from '@atlassian/people-teams/types';
import { getPeopleProfilePage } from '@atlassian/teams-common/urls';

import { PeopleMenuContext } from '../../context/PeopleMenuContext';
import { VOID_FUNC } from '../../utils/constants';
import { isModifiedEvent } from '../../utils/keys';
import { AnalyticsActionSubject, triggerAnalyticsForClickOnPeople } from '../analytics';
import { messages } from '../i18n';

import { MenuGroupSection } from './MenuGroupSection';
import { ImgIcon } from './utils';

interface PeopleMenuGroupProps {
	users?: User[];
	isLoading?: boolean;
}
type MenuGroupPeopleInternalProps = PeopleMenuGroupProps &
	WithAnalyticsEventsProps &
	WithMandatoryPeopleMenuConfigurationProps;

export const MenuGroupPeopleInternal: React.FC<MenuGroupPeopleInternalProps> = (
	props: MenuGroupPeopleInternalProps,
) => {
	const intl = useIntl();

	const { users = [], createAnalyticsEvent, isLoading, remoteConfig } = props;

	const {
		testId,
		product,
		onClickedItem = VOID_FUNC,
		pushRoute = VOID_FUNC,
		togglePeopleMenu = VOID_FUNC,
	} = useContext(PeopleMenuContext);

	const handleClickOnPeople = useCallback(
		(id: string) => (event: React.MouseEvent | React.KeyboardEvent) => {
			const isLeftClick = !isModifiedEvent(event);

			triggerAnalyticsForClickOnPeople(createAnalyticsEvent, isLeftClick);
			onClickedItem(id, 'people', event);

			if (isLeftClick) {
				const peopleProfilePage = getPeopleProfilePage(
					remoteConfig.resources,
					id,
					product,
					AnalyticsActionSubject.PEOPLE_MENU,
				);
				pushRoute(peopleProfilePage);
				event.preventDefault();
			}

			togglePeopleMenu(false);
		},
		[
			createAnalyticsEvent,
			onClickedItem,
			product,
			pushRoute,
			remoteConfig.resources,
			togglePeopleMenu,
		],
	);

	if (!remoteConfig.sections.collaborators.enabled) {
		return null;
	}

	return (
		<MenuGroupSection
			title={remoteConfig.sections.collaborators.label ?? intl.formatMessage(messages.yourCollab)}
			testId={`${testId}-people-section`}
			isLoading={isLoading}
			isEmpty={users.length === 0}
			sectionType={'user'}
			maxItems={remoteConfig.sections.collaborators.maxItems}
		>
			{users.slice(0, remoteConfig.sections.collaborators.maxItems).map((user: User) => (
				<LinkItem
					key={user.id}
					iconBefore={<ImgIcon src={user.avatarUrl} alt="" />}
					testId={`${testId}-people-item`}
					href={getPeopleProfilePage(
						remoteConfig.resources,
						user.id,
						product,
						AnalyticsActionSubject.PEOPLE_MENU,
					)}
					onClick={handleClickOnPeople(user.id)}
				>
					{user.fullName}
				</LinkItem>
			))}
		</MenuGroupSection>
	);
};

export const MenuGroupPeople = withAnalyticsEvents()(
	withMandatoryPeopleMenuConfiguration<PeopleMenuGroupProps & WithAnalyticsEventsProps>(
		MenuGroupPeopleInternal,
	),
);
