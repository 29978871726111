import { type RawTeamData, type Team } from '../types/team';

export function trimTeamAriInObject<T extends { id: string }>(team?: T) {
	if (!team) {
		return team;
	}

	return {
		...team,
		id: team.id.replace('ari:cloud:teams::team/', '').replace('ari:cloud:identity::team/', ''),
	};
}

export const transformTeams = (data?: RawTeamData): Team[] => {
	if (!data || !data.entities) {
		return [];
	}

	return data.entities.map(trimTeamAriInObject) as Team[];
};
