import React, { useCallback, useContext, useState } from 'react';

import { withAnalyticsEvents, type WithAnalyticsEventsProps } from '@atlaskit/analytics-next';
import { useOverflowStatus } from '@atlaskit/atlassian-navigation';
import Popup from '@atlaskit/popup';

import { PeopleMenuContext } from '../../context/PeopleMenuContext';
import { isCachedDataClear } from '../../hooks/useUsersTeamsData';
import { type PeopleMenuProps } from '../../types';
import { VOID_FUNC } from '../../utils/constants';
import { clearMeasure, startMeasure, stopMeasure } from '../../utils/performance';
import { triggerAnalyticsForHoverAndClick } from '../analytics';
import { MenuContent } from '../MenuContent/lazy';

import { PeopleMenuContentInternal } from './PeopleMenuContent';
import PeopleMenuProviders from './PeopleMenuProviders';
import PeopleMenuTrigger, { PeopleMenuTriggerSSR } from './PeopleMenuTrigger';
import { UsersTeamsPreFetch } from './PreFetchData';

type PeopleMenuInternalProps = Pick<
	PeopleMenuProps,
	'isHighlighted' | 'customChevronIcon' | 'onClick' | 'enablePreFetchingByHovering' | 'peopleText'
>;

// if `@atlaskit/popup` is upgraded from 0.6.x to 1.x,
// we may need to change this to an array of numbers like [0, 12];.
const OFFSET_FOR_MORE_BUTTON_VIEW = '12px -11px';
const eventuallyClearHoverMeasure = () => {
	setTimeout(() => {
		clearMeasure('hoverAndClick');
	}, 5 * SECOND);
};

const SECOND = 1000;

export function PeopleMenuInternal(props: PeopleMenuInternalProps & WithAnalyticsEventsProps) {
	const {
		customChevronIcon,
		isHighlighted,
		enablePreFetchingByHovering,
		createAnalyticsEvent,
		peopleText,
		onClick = VOID_FUNC,
	} = props;
	const [canPrefetch, setCanPrefetch] = useState(false);

	const {
		testId,
		isPeopleMenuOpen,
		isSSR,
		cloudId,
		orgId,
		userId,
		product,
		tenantUrl,
		togglePeopleMenu = VOID_FUNC,
		renotifyExperimentProps,
		shouldRenderToParent = false,
	} = useContext(PeopleMenuContext);
	const { isVisible } = useOverflowStatus();

	const closeMenu = useCallback(() => togglePeopleMenu(false), [togglePeopleMenu]);
	const handleClickOnTriggerButton = useCallback(() => {
		onClick(!isPeopleMenuOpen);
		togglePeopleMenu(!isPeopleMenuOpen);
		// measure duration of click and show menu
		startMeasure('clickAndShow');
		stopMeasure('hoverAndClick', (duration, startTime) => {
			triggerAnalyticsForHoverAndClick(createAnalyticsEvent, duration, startTime);
		});
	}, [createAnalyticsEvent, isPeopleMenuOpen, onClick, togglePeopleMenu]);

	const handleMouseEnterTriggerButton = useCallback(() => {
		MenuContent.preload();

		if (!canPrefetch) {
			setCanPrefetch(true);
			// measure duration time between hovering and clicking
			startMeasure('hoverAndClick');

			// clear all unwanted hovering after 5 seconds
			eventuallyClearHoverMeasure();
		}
	}, [canPrefetch, setCanPrefetch]);

	const buttonProps = {
		isSelected: isPeopleMenuOpen,
		onClick: handleClickOnTriggerButton,
		onMouseEnter: handleMouseEnterTriggerButton,
		testId: `${testId}-people-primary-button`,
		peopleText,
	};

	const PopupContent = useCallback(() => <PeopleMenuContentInternal />, []);

	if (isSSR) {
		return <PeopleMenuTriggerSSR testId={testId} peopleText={peopleText} />;
	}

	return (
		<>
			<Popup
				// @ts-ignore
				offset={isVisible ? undefined : OFFSET_FOR_MORE_BUTTON_VIEW}
				content={PopupContent}
				isOpen={isPeopleMenuOpen}
				placement={isVisible ? 'bottom-start' : 'right-start'}
				onClose={closeMenu}
				shouldRenderToParent={shouldRenderToParent}
				trigger={(triggerProps) => (
					<PeopleMenuTrigger
						isVisible={isVisible}
						isHighlighted={isHighlighted}
						customChevronIcon={customChevronIcon}
						triggerProps={triggerProps}
						{...buttonProps}
					/>
				)}
			/>

			{/* when cached data is empty and hovering on People trigger button */}
			{enablePreFetchingByHovering && canPrefetch && isCachedDataClear() && (
				<UsersTeamsPreFetch
					cloudId={cloudId}
					orgId={orgId}
					userId={userId}
					product={product}
					tenantUrl={tenantUrl}
					renotifyExperiment={renotifyExperimentProps}
				/>
			)}
		</>
	);
}

const PeopleMenuInternalWithAnalytics = withAnalyticsEvents()(PeopleMenuInternal);

export default function PeopleMenu(props: PeopleMenuProps) {
	const {
		isHighlighted,
		onClick,
		enablePreFetchingByHovering,
		customChevronIcon,
		peopleText,
		...providerProps
	} = {
		...props,
	};

	return (
		<PeopleMenuProviders {...providerProps}>
			<PeopleMenuInternalWithAnalytics
				customChevronIcon={customChevronIcon}
				isHighlighted={isHighlighted}
				onClick={onClick}
				enablePreFetchingByHovering={enablePreFetchingByHovering}
				peopleText={peopleText}
			/>
		</PeopleMenuProviders>
	);
}
